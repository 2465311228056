import React from 'react'
import { useEffect } from 'react'
import Layout from '../../../components/Layout'
import Seo from '../../../components/Seo'
import i18n from '../../../locales/he.yaml'
import { fireEvent } from '../../../analytics'

const ExhibitionThanksTemplate = () => {
  useEffect(() => {
    fireEvent('Lead')
  }, [])
  return (
    <div className='hero has-navbar-fixed-top'>
      <div className='hero-body'>
        <div className='container content'>
          <p>{i18n.exhibitionThanks.text1}</p>
          {i18n.exhibitionThanks.text1Done && (
            <p>{i18n.exhibitionThanks.text1Done}</p>
          )}
          {i18n.exhibitionThanks.callToActionDoneLink && (
            <p className='is-flex justify-center'>
              <a
                href={i18n.exhibitionThanks.callToActionDoneLink}
                className='button is-nude is-flex items-center justify-start'
                target='_blank'
                rel='noreferrer'
              >
                {i18n.exhibitionThanks.callToActionDone}
              </a>
            </p>
          )}
          <h2>{i18n.exhibitionThanks.header}</h2>
          <p>
            {i18n.exhibitionThanks.text2Done || i18n.exhibitionThanks.text2}
          </p>
          <p className='is-flex justify-center'>
            <a
              href={i18n.exhibitionThanks.callToActionLink}
              className='button is-nude is-flex items-center justify-start'
              target='_blank'
              rel='noreferrer'
            >
              {i18n.exhibitionThanks.callToAction}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

const ExhibitionThanks = ({ location: { pathname } }) => {
  return (
    <Layout>
      <Seo
        metadata={i18n.exhibitionThanks.metadata}
        pathname={pathname}
        robots='noindex, nofollow'
      />
      <ExhibitionThanksTemplate />
    </Layout>
  )
}

export default ExhibitionThanks
